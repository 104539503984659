export const initSwiper = async () => {
    const column = document.querySelector('.column')

    const body = document.querySelector('body')
    body.addEventListener('touchstart', startTouch)
    body.addEventListener('touchmove', (event) => shuffle(event), false)
    // body.addEventListener('touchend', stopTouch)

    const indicators = document.querySelector('#indicators')

    const windowHeight = (typeof window.outerHeight != 'undefined') ? Math.max(window.outerHeight, window.innerHeight) : window.innerHeight
    const cardMargin = 20;

    let isAnimating = false;

    let cardsData;
    let maxCards = 0;

    let prevCard = {}
    let currentCard = {}
    let nextCard = {}
    let fillerCardBottom = {}
    let fillerCardTop = {}

    let currentCardIndex = 0;

    let columnHeight = 0;
    let cardHeight = 0;
    let initialScrollerTop = 0;

    let initialY = null;

    let prevCardElement = document.querySelector('.card-prev');
    let currentCardElement = document.querySelector('.card-current');
    let nextCardElement = document.querySelector('.card-next');
    let fillerCardElementBottom = document.querySelector('.card-filler-bottom');
    let fillerCardElementTop = document.querySelector('.card-filler-top');

    const updateCards = () => {

        if (currentCardIndex > maxCards - 1) {
            currentCardIndex = 0
        }
        if (currentCardIndex < 0) {
            currentCardIndex = maxCards - 1
        }

        let prevCardIndex = currentCardIndex - 1;
        let nextCardIndex = currentCardIndex + 1;

        if (prevCardIndex < 0) {
            prevCardIndex = maxCards - 1
        }

        if (nextCardIndex > maxCards - 1) {
            nextCardIndex = 0
        }

        // INFO: Das muss hier gesetzt werden, damit es keine Sprünge gibt
        let fillerCardBottomIndex = nextCardIndex + 1;

        if (fillerCardBottomIndex > maxCards - 1) {
            fillerCardBottomIndex = 0
        }
        // INFO: Das muss hier gesetzt werden, damit es keine Sprünge gibt
        let fillerCardTopIndex = prevCardIndex - 1;

        if (fillerCardTopIndex < 0) {
            fillerCardTopIndex = maxCards - 1
        }

        prevCard = cardsData[prevCardIndex];
        currentCard = cardsData[currentCardIndex];
        nextCard = cardsData[nextCardIndex];
        fillerCardBottom = cardsData[fillerCardBottomIndex];
        fillerCardTop = cardsData[fillerCardTopIndex];
    }

    const setCardContent = () => {
        fillerCardElementTop.innerHTML = `<img src="${fillerCardTop.image}"><div class="info" style="background-color:${fillerCardTop.color}">${fillerCardTop.title}</div><div class="play"></div>`;
        column.querySelector('.perspective').append(fillerCardElementTop);

        prevCardElement.innerHTML = `<img src="${prevCard.image}"><div class="info" style="background-color:${prevCard.color}">${prevCard.title}</div><div class="play"></div>`;
        column.querySelector('.perspective').append(prevCardElement);

        currentCardElement.innerHTML = `<img src="${currentCard.image}"><div class="info" style="background-color:${currentCard.color}">${currentCard.title}</div><div class="play"></div>`;
        column.querySelector('.perspective').append(currentCardElement);

        nextCardElement.innerHTML = `<img src="${nextCard.image}"><div class="info" style="background-color:${nextCard.color}">${nextCard.title}</div><div class="play"></div>`;
        column.querySelector('.perspective').append(nextCardElement);

        fillerCardElementBottom.innerHTML = `<img src="${fillerCardBottom.image}"><div class="info" style="background-color:${fillerCardBottom.color}">${fillerCardBottom.title}</div><div class="play"></div>`;
        column.querySelector('.perspective').append(fillerCardElementBottom);


    }

    const init = (json) => {
        cardsData = json;
        maxCards = cardsData.length;

        if (maxCards === 0) {
            return false;
        }

        updateCards();
        setCardContent();

        cardHeight = document.querySelector('.card').offsetHeight;
        columnHeight = column.offsetHeight;
        currentCardElement.addEventListener('animationend', transitionEndCallback);
        currentCardElement.addEventListener('click', showVideo)

        initialScrollerTop = (columnHeight / 2) - (windowHeight / 2) + cardMargin * 2;
        column.style.top = `-${initialScrollerTop}px`

        const main = document.querySelector('main');
        main.style.overflow = 'hidden';

        for (let index = 0; index < maxCards; index++) {
            const indicator = document.createElement('div')
            indicator.classList.add('indicator')

            if (index === currentCardIndex) {
                indicator.classList.add('current')
            }

            indicators.append(indicator)
        }

        const indicatorTop = windowHeight / 2 - indicators.offsetHeight / 2 - 20;
        indicators.style.top = `${indicatorTop}px`
    }

    const transitionEndCallback = () => {
        column.classList.remove('animate');

        currentCardElement.removeEventListener('click', showVideo)
        currentCardElement.removeEventListener('animationend', transitionEndCallback);

        currentCardElement.classList.add('card-prev')
        currentCardElement.classList.remove('card-current', 'becomePrev', 'becomeNext')

        nextCardElement.classList.add('card-current')
        nextCardElement.classList.remove('card-next', 'fadeOut', 'nextBecomeCurrent', 'prevBecomeCurrent')

        prevCardElement.classList.add('card-next')
        prevCardElement.classList.remove('card-prev', 'fadeOut', 'nextBecomeCurrent', 'prevBecomeCurrent')

        prevCardElement = document.querySelector('.card-prev');
        currentCardElement = document.querySelector('.card-current');
        nextCardElement = document.querySelector('.card-next');

        nextCardElement.remove();
        column.querySelector('.perspective').append(nextCardElement);
        column.style.top = `-${initialScrollerTop}px`

        currentCardElement.addEventListener('animationend', transitionEndCallback);
        currentCardElement.addEventListener('click', showVideo)

        setCardContent()

        isAnimating = false;
    }


    const getCardData = () => {
        const wrapper = document.querySelector('#swipewrapper');

        if (!wrapper) {
            return;

        }

        const url = `${wrapper.dataset.url}.json`;
        fetch(url)
            .then((response) => {
                return response.json()
            })
            .then((json) => {
                init(json);
            })
    }


    function startTouch(event) {
        event.preventDefault();
        initialY = event.touches[0].clientY;
    }

    function getSwipeDirection(event) {
        let direction = null

        if (initialY === null) {
            return;
        }

        const diffY = initialY - event.touches[0].clientY;


        if (diffY > 0) {
            direction = -1
        } else {
            direction = 1
        }


        initialY = null;

        return direction
    }

    function shuffle(event, target) {
        const direction = getSwipeDirection(event);
        // const newTopPosition = cardHeight * direction - cardHeight;
        let newTopPosition = 0;

        if (isAnimating === true) {
            return
        }

        if (!direction) {
            return
        }

        isAnimating = true;

        if (direction < 0) {
            const scrollerTop = document.querySelector('.column').offsetTop;
            newTopPosition = (scrollerTop - cardHeight - cardMargin * 2)

            currentCardIndex++;

            prevCardElement.classList.add('fadeOut')
            currentCardElement.classList.add('becomePrev')
            nextCardElement.classList.add('nextBecomeCurrent')

        } else {
            const scrollerTop = document.querySelector('.column').offsetTop;
            newTopPosition = (scrollerTop + cardHeight)

            currentCardIndex--;

            nextCardElement.classList.add('fadeOut')
            currentCardElement.classList.add('becomeNext')
            prevCardElement.classList.add('prevBecomeCurrent')

        }

        updateCards();

        const indicator = document.querySelector('.indicator.current')
        const indicatorList = document.querySelectorAll('.indicator')

        const nextIndicator = indicatorList[currentCardIndex]

        if (indicator !== null) {
            indicator.classList.remove('current')
        }

        if (nextIndicator !== null) {
            nextIndicator.classList.add('current')
        }

        column.classList.add('animate');
        column.style = `top: ${newTopPosition}px`

    }



    function showVideo(event) {

        const player = document.querySelector('#player')
        const iframe = document.querySelector('#vimeo')

        let url = cardsData[currentCardIndex].videoUrl.replace('https://vimeo.com', 'https://player.vimeo.com/video');
        const slashes = url.split('/')

        if (slashes.length > 5) {
            var index = url.lastIndexOf('/');
            url = url.substr(0, index + 1) + '?h=' + url.substr(index + 1) + '&';
        } else {
            url = url + '?'
        }

        player.addEventListener('click', function (event) {
            event.preventDefault();
            event.stopPropagation();
            iframe.src = '#';
            player.classList.remove('show');
            isAnimating = false;
        })

        iframe.src = url + 'loop=false&autoplay=true&muted=false&gesture=media&playsinline=true&byline=false&portrait=true&title=false&speed=true&transparent=true&customControls=true&fullscreen=true'


        player.classList.add('show')
        isAnimating = false;

        // player.addEventListener('transitionend', () => {
        //     if (player.classList.contains('show')) {
        //         iframe.src = url + 'loop=false&autoplay=true&muted=false&gesture=media&playsinline=true&byline=false&portrait=true&title=false&speed=true&transparent=true&customControls=true&fullscreen=true'
        //     }
        // })


    }

    getCardData()

}