import { initSwiper } from './swipe'
import barba from '@barba/core';
import barbaCss from '@barba/css';

barba.use(barbaCss);


barba.init({
    debug: true,
    transitions: [{
        name: 'barba',
        once(event) { }
    },
    ]
});


barba.hooks.beforeEnter((data) => {

    const initStuff = async () => {
        //  await initSwiper()

        const swipewrapper = document.querySelector('#swipewrapper')

        if (swipewrapper) {
            await initSwiper()
        }
    }

    initStuff()
});

barba.hooks.beforeLeave((data) => {
    const shield = document.querySelector('.shield')
    const main = document.querySelector('body')

    shield.style.backgroundColor = data.trigger.dataset.color

    main.classList.remove('cards-animation', 'default-animation');
    main.classList.add(data.trigger.dataset.animation);
});



const swipewrapper = document.querySelector('#swipewrapper')

if (swipewrapper) {
    initSwiper()
}